
<template>
  <v-container>
    <v-row justify="center" no-gutters>
      <div id="map"></div>
    </v-row>
  </v-container>
</template>

<script>
import Raphael from "raphael";

const style = {
  fill: "#C4C4C4",
  "stroke-width": 0,
  cursor: "pointer",
};

export default {
  data() {
    return {
      map: null, // raphael object
      regions: {},
    };
  },

  mounted() {
    const mapContainer = document.getElementById("map");
    this.map = Raphael(mapContainer, 554, 458);

    this.map.path;

    this.paintMap();
    this.handleEvents();
  },

  methods: {
    paintMap() {
      // map
      this.regions["Map"] = this.map
        .path("M163 0H346V194H395V80.5L522 81V194H551V455H0V194H163V0Z")
        .attr({
          fill: "#454545",
        });

      // vip
      this.regions["VipA"] = this.map.path("M167 4H252V194H167V4Z");
      this.regions["VipB"] = this.map.path("M256 4H342V194H256V4Z");

      // stream
      this.regions["StreamA"] = this.map.path("M399 85H457V194H399V85Z");
      this.regions["StreamB"] = this.map.path("M461 85H518V194H461V85Z");

      // islands
      this.regions["Ilha1-PC"] = this.map.path(
        "M82 363L112.434 385.111L100.809 420.889H63.1909L51.5662 385.111L82 363Z"
      );
      this.regions["Ilha2-PC"] = this.map.path(
        "M82 218L112.434 240.111L100.809 275.889H63.1909L51.5662 240.111L82 218Z"
      );
      this.regions["Ilha3-PC"] = this.map.path(
        "M198 266L228.434 288.111L216.809 323.889H179.191L167.566 288.111L198 266Z"
      );
      this.regions["Ilha4-XB"] = this.map.path(
        "M326 246L356.434 268.111L344.809 303.889H307.191L295.566 268.111L326 246Z"
      );
      this.regions["Ilha5-ps"] = this.map.path(
        "M458 265L488.434 287.111L476.809 322.889H439.191L427.566 287.111L458 265Z"
      );

      // tvs
      this.regions["TvPS1"] = this.map.path("M498 372H514V408H498V372Z");
      this.regions["TvPS2"] = this.map.path("M356 372H372V408H356V372Z");
      this.regions["TvPS3"] = this.map.path("M331 372H347V408H331V372Z");
      this.regions["TvPS4"] = this.map.path("M180 372H196V408H180V372Z");
      this.regions["TvXB1"] = this.map.path("M498 415H514V451H498V415Z");
      this.regions["TvXB2"] = this.map.path("M356 415H372V451H356V415Z");
      this.regions["TvXB3"] = this.map.path("M331 415H347V451H331V415Z");
      this.regions["TvXB4"] = this.map.path("M180 415H196V451H180V415Z");
    },

    handleEvents() {
      const animationSpeed = 250;

      const hoverStyle = {
        fill: "#FF8A00",
      };

      for (let regionName in this.regions) {
        (function (region, self) {
          if (regionName != "Map") {
            region.attr(style);

            // change color on hover
            region[0].addEventListener(
              "mouseover",
              function () {
                region.animate(hoverStyle, animationSpeed);
              },
              true
            );

            // return color
            region[0].addEventListener(
              "mouseout",
              function () {
                region.animate(style, animationSpeed);
              },
              true
            );

            // get event click
            region[0].addEventListener("click", function (event) {
              console.log(region.id);
            });
          }
        })(this.regions[regionName], this);
      }
    },
  },
};
</script>

<style>
</style>